<template>
    <!--搜索框-->
    <div class="search" v-if="Object.keys(searchToProps).length">
        <!-- 自动生成搜索条件 -->
        <div v-for="(item, key) in search" :key="key"
             :class="{borderBottom: !item.isHidden && search.length - 1 !== key}">
            <!--文本搜索-->
            <div v-if="item.type === 'input'" class="search-item">
                <div class="label">
                    {{ item.label }} ：
                </div>
                <el-input v-model="item.value"
                          clearable
                          placeholder="请输入内容"
                          prefix-icon="el-icon-search"
                          style="width: 400px;"
                />
            </div>
            <!-- 按类别搜索 -->
            <div v-if="item.type === 'selectOption' || item.type === 'selectString'" class="search-item">
                <div v-if="item.label" class="label">
                    {{ item.label }} ：
                </div>
                <div class="item-class">
                    <div v-for="(selectItem, key1) in item.options"
                         :key="key1"
                         :class="{selected: selectItem.value === item.value}"
                         class="label"
                         @click="item.value = selectItem.value"
                    >
                        {{ selectItem.label }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "search",
    props: {
        // 搜索字段映射，名称使用 <数据库字段名称>
        searchToProps: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            // 搜索条件
            search: [],
        }
    },
    watch: {
        search: {
            handler() {
                this.$emit('search', this.search)
            },
            deep: true
        }
    },
    created() {
        this.init()
    },
    methods: {
        // 初始化
        init() {
            // 初始化搜索属性
            let arr = []
            for (const key in this.searchToProps) {
                let obj = {}
                for (const objKey in this.searchToProps[key]) {
                    obj[objKey] = this.searchToProps[key][objKey]
                }
                if (!('value' in obj)) {
                    obj['value'] = ''
                }
                obj['name'] = key

                arr.push(obj)
            }
            this.search = arr
        }
    }
}
</script>

<style scoped lang="scss">

::v-deep .el-input__inner {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #DCDFE6;
}

.search {
    margin-top: 20px;
    margin-bottom: 25px;
    background-color: #FFFFFF;
    padding: 20px;

    .borderBottom {
        border-bottom: 1px dashed #e9e9e9;
    }

    .search-item {
        display: flex;
        line-height: 55px;
        align-items: center;

        .item-class {
            display: flex;
            align-items: center;
            margin-left: 10px;
            flex-wrap: wrap;
            flex: 1;

            .label {
                margin-right: 10px;
            }

            .label {
                margin-right: 10px;
                height: 28px;
                padding: 0 15px;
                border-radius: 14px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 5px;
                margin-top: 5px;
            }

            .selected, .label:hover {
                background-color: #f29100;
                color: #FFFFFF;
            }
        }

    }

}
</style>
<template>
    <div>
        <!--搜索框-->
        <search :search-to-props="searchToProps" @search="searchData"/>
        <!--  课程目录 -->
        <div class="package-list">
            <!--显示条数-->
            <div v-if="isShowResultNum" class="package-tips">
                共{{ total }}个结果
            </div>
            <!--列表项-->
            <el-row :gutter="20">
                <el-col :span="span" v-for="(item, key) in packageList" :key="key" @click.native="$emit('clickItem', item)">
                    <slot v-bind:item="item"></slot>
                </el-col>
            </el-row>
            <!--空状态-->
            <el-empty v-if="isEmpty" description="好像什么都没有~"></el-empty>
        </div>
        <!--    分页按钮-->
        <div style="text-align: center; margin-top: 50px;">
            <el-pagination
                layout="prev, pager, next"
                :current-page.sync="currentPage"
                :page-size="pageSize"
                :total="total"
                :hide-on-single-page="true"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import Search from "./search"

export default {
    name: "PageList",
    components: {Search},
    props: {
        // 搜索字段映射，名称使用 <数据库字段名称>
        searchToProps: {
            type: Object,
            default() {
                return {}
            }
        },
        // 数据表单
        packageList: {
            type: Array,
            default() {
                return []
            }
        },
        // 数据列表展示参数
        span: {
            type: Number,
            default: 6
        },
        // 是否显示结果数量
        isShowResultNum: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            // 当前页数
            currentPage: 1,
            // 每页条数
            pageSize: 12,
            // 总条数
            total: 0,
            // 数据是否为空
            isEmpty: false,
            // 搜索条件
            search: []
        }
    },

    methods: {
        // 搜索数据，初始化当前页数为 1
        searchData(search) {
            this.search = search
            this.currentPage = 1

            let data = {
                pageNum: this.currentPage,
                pageSize: this.pageSize,
                search: this.search
            }
            this.$emit('search', data)
        },
        // 更新数据总数
        updateTotal(val) {
            this.total = val
            this.isEmpty = val === 0
        },
        // 获取数据列表接口
        handleCurrentChange(val) {
            this.currentPage = val
            let data = {
                pageNum: this.currentPage,
                pageSize: this.pageSize,
                search: this.search
            }
            this.$emit('search', data)
        },
        // 更换每页条数，重新获取数据
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.searchData(this.search)
        }
    }
}
</script>

<style>
.el-pager li, .el-pagination .btn-next, .el-pagination .btn-prev, .el-pagination button:disabled {
    background: transparent !important;
}
</style>

<style scoped lang="scss">

::v-deep .el-pager li.active, .el-pager li:hover {
    color: #f29100 !important;
}

::v-deep .el-pagination .btn-prev:hover, .el-pagination .btn-next:hover {
    color: #f29100 !important;
}

.package-list {
    .package-tips {
        text-align: right;
        font-size: 13px;
        color: $sr-main-tips;
        margin-bottom: 25px;
    }
}
</style>
<template>
    <div>
        <!--搜索框-->
        <div class="search">
            <div class="search-item">
                <div class="item-class">
                    <div class="label"
                         :class="{selected: index === 0}"
                         @click="changeIndex(0)"
                    >
                        线下集训
                    </div>
                    <div class="label"
                         :class="{selected: index === 1}"
                         @click="changeIndex(1)"
                    >
                        一对一加油包
                    </div>
                </div>
            </div>
        </div>
        <!--  课程目录 -->
        <page-list ref="packageInfo"
                   :package-list="packageList"
                   @search="search"
                   @clickItem="clickItem">
            <!--单个学习包-->
            <template v-slot="scope">
                <package-card price-name="详情"
                              :item="scope.item"
                              :label-name="index ? '加油包' : '线下集训'"
                />
            </template>
        </page-list>
    </div>
</template>

<script>

import PageList from "../components/pageList";
import PackageCard from "../components/packageCard";

export default {
    name: "index",
    components: {PackageCard, PageList},
    data() {
        return {
            packageList: [],
            index: 0
        }
    },
    mounted() {
        this.$refs.packageInfo.handleCurrentChange(1)
    },
    methods: {
        // 更改下标
        changeIndex(val) {
            if (this.index !== val) {
                this.index = val
                // 设置当前页数为1
                this.$refs.packageInfo.handleCurrentChange(1)
            }
        },
        // 搜索数据
        search(data) {
            // 线下集训
            let url = ''
            if (this.index === 0) {
                url = '/find/index/getOfflineList'
                data['search'] = [{
                    name: 'offline_type',
                    type: 'eq',
                    value: this.$store.state.userType
                }]
            } else {
                url = '/find/index/getRefuelsList'
                data['search'] = [{
                    name: 'refuels_type',
                    type: 'eq',
                    value: this.$store.state.userType
                }]
            }
            // 向后端发送请求数据
            this.$api.post(url, data).then(res => {
                this.packageList = res.data.records
                this.$refs.packageInfo.updateTotal(res.data.total)
            }).catch(err => {
                console.log('数据获取失败', err)
            })
        },
        // 点击数据
        clickItem(item) {
            // 去淘宝链接
            window.open(item.buyLink)
        }
    }
}
</script>

<style scoped lang="scss">

.search {
    margin-top: 20px;
    padding: 20px 0;

    .search-item {
        display: flex;
        line-height: 55px;
        align-items: center;

        &:not(:last-child) {
            border-bottom: 1px dashed #e9e9e9;
        }

        .item-class {
            display: flex;
            align-items: center;
            margin-left: 10px;

            span {
                margin-right: 10px;
            }

            .label {
                margin-right: 20px;
                height: 33px;
                width: 110px;
                border-radius: 5px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .selected, .label:hover {
                background-color: #f29100;
                color: #FFFFFF;
            }
        }

    }

}
</style>
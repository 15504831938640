<template>
    <div class="package-card">
        <!--封面图片-->
        <el-image class="img" fit="cover" :src="item.coverImgUrl"/>
        <!--课程标题-->
        <div class="package-name">
            {{ item.name }}
        </div>
        <!--课程信息-->
        <div class="package-info">
            <div class="package-price" style="float: left">
                <span v-if="priceName">
                    {{ priceName }}
                </span>
                <span v-else>
                    {{ item.price ? ('￥' + (item.price / 100).toFixed(2)) : '免费' }}
                </span>
            </div>
            <div style="float: right">
                <el-tag type="info" size="small">{{ labelName }}</el-tag>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "packageCard",
    props: {
        // 单个卡片
        item: {
            type: Object,
            default() {
                return {}
            }
        },
        // 当前课程类型
        labelName: {
            type: String,
            default: '学习包'
        },
        // 费用提示语
        priceName: {
            type: String,
            default: undefined
        }
    }
}
</script>

<style scoped lang="scss">
.package-card {
    background-color: #FFFFFF;
    width: 100%;
    height: 270px;
    border-radius: 4px;
    margin-bottom: 40px;
    cursor: pointer;
    border: 1px solid #eee;

    &:hover {
        transition: all 0.2s;
        transform: translate3d(0, -10px, 0);
    }

    .img {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        width: 100%;
        height: 160px;
    }

    .package-name {
        @include text-overflow(1);
        white-space: pre;
        font-size: 17px;
        padding: 15px;
        font-weight: 400;
        text-shadow: 0 0 0.5px #333;
        line-height: 1.5;
        color: #333;
    }

    .package-info {
        padding: 10px 15px;

        .package-price {
            font-size: 19px;
            color: $sr-main-color;
            font-weight: 600;
        }
    }
}
</style>